@font-face {
  font-family: "SFRegular";
  src: url("../../assets/fonts/SFProText-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SFLight";
  src: url("../../assets/fonts/SFProText-Light.ttf") format("truetype");
}

@font-face {
  font-family: "SFMedium";
  src: url("../../assets/fonts/SFProText-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "SFBold";
  src: url("../../assets/fonts/SFProText-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "SFSemibold";
  src: url("../../assets/fonts/SFProText-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: "SFHeavy";
  src: url("../../assets/fonts/SFProText-Heavy.ttf") format("truetype");
}
