@import "./Reset.scss";
@import "./Fonts.scss";

// Vars:
$main: #20a3b2;
$boldContent: #152238;

body,
html {
  overflow-x: hidden;
}

.App {
  height: 100%;
}
.Home {
  display: flex;
  flex-direction: column;
  // height: 100%;
  // background: linear-gradient(90deg, #e9f9fb, #d9f5f8);
  background-color: #fff;
  // padding: 0 5%;
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    position: relative;
    h1 {
      display: flex;
      align-items: center;
      p {
        font-size: 1.5em;
        color: #222;
        font-family: "SFBold";
        letter-spacing: 2px;
        margin: 0 10px;
      }
      span {
        font-family: "SFLight";
      }
    }
    .logo {
      width: 3vw;
      height: 3vw;
      img {
        width: 100%;
      }
    }

    .langs {
      display: flex;
      align-items: center;
      position: absolute;
      right: 10%;

      .lang {
        width: 30px;
        height: 30px;
        margin: 0 5px;
        cursor: pointer;
        img {
          width: 100%;
        }
      }
    }
    // top:
  }

  .inner {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // position: relative;
    // height: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 5%;
    left: 5%;
    top: 80px;
    // background-color: red;

    p {
      display: inline-block;
      font-family: "SFRegular";
      font-size: 2em;
      color: #222;
      text-align: center;
      line-height: 1.5em;
      span {
        color: $main;
        font-family: "SFMedium";
      }
    }

    .btn__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0.8vw 0;

      a {
        width: 8vw;
        margin: 0 0.5vw;

        img {
          width: 100%;
        }
      }
    }
  }

  .images__container {
    display: flex;
    flex: 1;
    align-items: flex-end;
    justify-content: center;
    height: calc(100vh - 150px);
    // position: absolute;
    // right: 5%;
    // left: 5%;
    // bottom: 50px;

    .images__col {
      display: flex;
      flex-direction: column;
      margin: 0 1vw;
      .image__container {
        margin: 1vw 0;
        position: relative;
        width: 10vw;
        max-width: 500px;
        overflow: hidden;
        img {
          display: block;
          width: 100%;
        }
      }
      .shadow {
        position: absolute;
        width: 11vw;
        height: 115%;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: linear-gradient(rgba(255, 255, 255, 0), #fff);
      }
    }
    .images__col:nth-child(3),
    .images__col:nth-child(5) {
      margin-top: 100px;
      align-self: center;
    }
    .images__col:first-child,
    .images__col:last-child {
      margin-bottom: 80px;
      // align-self: flex-end;
    }
  }

  .images__container--size-s {
    display: none;
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 10px 5%;
    box-sizing: border-box;
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #fff;
    box-shadow: -5px 0px 15px #f6f6f6;
    p {
      font-size: 0.8em;
      color: #595959;
      font-family: "SFRegular";
      line-height: 1.7em;
      margin: 0 0.5vw;
    }
    .ruls {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin: 1vw 0;
      a {
        margin: 0 0.5vw;
      }
    }
  }
}

.impressum {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 20px auto;
  font-size: 16px;
  .logo__container {
    width: 400px;
    margin: 20px 0;
  }
  .logo {
    width: 100%;
  }
  h1 {
    font-size: 2.5em;
    font-family: SFBold;
    color: #333;
    margin: 40px 0;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  .block {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    h2 {
      font-size: 1.1em;
      font-family: SFBold;
      color: #333;
      margin-bottom: 10px;
    }
    h3 {
      font-size: 1em;
      font-family: SFBold;
      color: #333;
    }
    p {
      font-size: 0.9em;
      font-family: SFRegular;
      color: #595959;
      line-height: 1.7em;
      margin: 8px 0;
    }
  }
  .notice {
    margin: 80px 0 80px 0;
    h2 {
      font-size: 1.3em;
      align-self: center;
    }
    a {
      color: $main;
    }
  }
}
@media all and (min-width: 1281px) {
}

@media all and (min-width: 1025px) and (max-width: 1280px) {
  .Home {
    // overflow: scroll;
    .inner {
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;
      // position: relative;
      // height: 100%;
    }

    .content {
      display: flex;
      flex-direction: column;
      // flex: 1;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 5%;
      left: 5%;
      top: 80px;

      p {
        display: inline-block;
        font-family: "SFRegular";
        font-size: 2em;
        color: #222;
        text-align: center;
        line-height: 1.5em;
        span {
          color: $main;
          font-family: "SFMedium";
        }
      }

      .btn__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0.8vw 0;

        a {
          width: 8vw;
          margin: 0 0.5vw;

          img {
            width: 100%;
          }
        }
      }
    }

    .images__container {
      display: flex;
      flex: 1;
      align-items: flex-end;
      justify-content: center;
      height: calc(100vh - 150px);
      // position: absolute;
      // right: 5%;
      // left: 5%;
      // bottom: 50px;

      .images__col {
        display: flex;
        flex-direction: column;
        margin: 0 1vw;
        // background-color: red;
        img {
          width: 10vw;
        }
        .image__container {
          margin: 1vw 0;
          position: relative;
        }
      }
      .images__col:last-child,
      .images__col:first-child {
        // margin-bottom: 100px;
      }
      .images__col:nth-child(2n + 0) {
        // align-self: flex-end;
      }
      .images__col:nth-child(3),
      .images__col:nth-child(5) {
        // margin-top: 100px;
        align-self: center;
      }
    }

    .images__container--size-s {
      display: none;
    }

    .footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 50px;
      padding: 10px 5%;
      position: fixed;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: #fff;
      box-shadow: -5px 0px 15px #f6f6f6;
      p {
        font-size: 0.8em;
        color: #595959;
        font-family: "SFRegular";
        line-height: 1.7em;
        margin: 0 0.5vw;
      }
      .ruls {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .impressum {
    font-size: 14px;
    .logo__container {
      width: 350px;
    }

    .block {
      h2 {
        margin-bottom: 8px;
      }
      h3 {
        line-height: 1.7em;
      }
    }
    .notice {
      margin: 50px 0;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Home {
    font-size: 14px;
    .header {
      .logo {
        width: 5vw;
        height: 5vw;
      }
    }
    .inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      // position: relative;
      height: calc(100vh - 150px);
    }

    .content {
      // flex: 1;
      position: static;
      right: 0;
      left: 0;
      top: 10px;
      margin-top: 10px;

      .btn__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0.8vw 0;

        a {
          width: 18vw;
          margin: 1vw;
        }
      }
    }

    .images__container {
      display: flex;
      flex: 2;
      margin-bottom: 30px;
      align-items: center;

      .images__col {
        margin: 0 1vw;
        img {
          width: 11vw;
        }
      }
      .images__col:last-child,
      .images__col:first-child {
        // align-self: center;
      }
      .images__col:nth-child(2n + 0) {
        margin-top: 100px;
      }
      .images__col:nth-child(3),
      .images__col:nth-child(5) {
        margin-top: 150px;
        // align-self: center;
      }
      .images__col:nth-child(4) {
        align-self: flex-end;
        margin-bottom: 50px;
      }
    }

    .footer {
      p {
        font-size: 0.9em;
      }
    }
  }

  .impressum {
    width: 75%;
    font-size: 13px;
    .logo__container {
      width: 300px;
    }

    h1 {
      margin: 30px 0;
    }
    .block {
      h3 {
        line-height: 1.8em;
      }
      p {
        font-size: 1em;
      }
    }
    .notice {
      margin: 40px 0 80px 0;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .Home {
    font-size: 15px;
    .header {
      h1 {
        p {
          font-size: 1.4em;
        }
      }
      .logo {
        width: 4vw;
        height: 4vw;
        // img {
        //   width: 100%;
        // }
      }
    }

    .content {
      position: absolute;
      right: 5%;
      left: 5%;
      top: 100px;

      p {
        font-size: 1.8em;
        line-height: 1.7em;
      }

      .btn__container {
        margin: 1vw;

        a {
          width: 10vw;
          margin: 1vw;
        }
      }
    }

    .images__container {
      display: flex;
      flex: 1;

      .images__col {
        img {
          width: 11vw;
        }
      }
      .images__col:nth-child(4) {
        margin-bottom: 0;
      }
    }
  }

  .impressum {
    width: 75%;
    font-size: 13px;
    .logo__container {
      width: 300px;
    }

    h1 {
      margin: 30px 0;
    }
    .block {
      h3 {
        line-height: 1.8em;
      }
      p {
        font-size: 1em;
      }
    }
    .notice {
      margin: 40px 0 80px 0;
    }
  }
}

@media all and (min-width: 481px) and (max-width: 767px) {
  .Home {
    font-size: 14px;
    // overflow-y: scroll;
    padding: unset;
    min-height: 100%;

    .header {
      height: 70px;
      h1 {
        p {
          font-size: 1.4em;
        }
      }
      .logo {
        width: 8vw;
        height: 8vw;
      }
    }

    .inner {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      // height: 100%;
      // overflow: scroll;
    }

    .content {
      // flex: 1;
      position: static;
      right: 0;
      left: 0;
      top: 0;
      p {
        font-size: 1.4em;
        line-height: 1.5em;
      }

      .btn__container {
        margin: 2vw 0;

        a {
          width: 25vw;
          margin: 0 1vw;
        }
      }
    }

    .images__container {
      display: none;
    }

    .images__container--size-s {
      display: inline-block;
      margin: 10px 0;

      .image__container {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        img {
          width: 90%;
        }
      }
    }

    .footer {
      margin: 0 auto;
      // width: 100%;
      position: static;
      left: 0;
      right: 0;
      p {
        margin: 0 1vw;
      }
    }
  }

  .impressum {
    width: 75%;
    font-size: 13px;
    .logo__container {
      width: 250px;
      margin: 10px 0;
    }
    h1 {
      font-size: 2.3em;
      margin: 30px 0;
    }
    .block {
      h2 {
        line-height: 1.7em;
        margin-bottom: 5px;
      }
      h3 {
        line-height: 1.7em;
      }
      p {
        font-size: 1em;
      }
    }
    .notice {
      margin: 40px 0;
      h2 {
        font-size: 1.2em;
      }
    }
  }
}

@media all and (min-width: 320px) and (max-width: 480px) {
  .Home {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    padding: 0;
    min-height: 100%;
    .header {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      height: 100px;
      h1 {
        p {
          font-size: 1.4em;
        }
      }
      .logo {
        width: 9vw;
        height: 9vw;
      }
      .langs {
        display: flex;
        align-items: center;
        position: static;
        right: 0;
        margin-bottom: 10px;

        .lang {
          width: 30px;
          height: 30px;
          margin: 0 5px;
          cursor: pointer;
          img {
            width: 100%;
          }
        }
      }
    }

    .inner {
      display: flex;
      flex: 1;
      flex-direction: column;
    }

    .content {
      // flex:
      position: static;
      right: 0;
      left: 0;
      top: 0;
      padding: 0 5%;
      margin-bottom: 10px;
      p {
        font-size: 1.3em;
        line-height: 1.5em;
      }

      .btn__container {
        margin: 3vw 0;

        a {
          width: 30vw;
          margin: 0 1vw;
        }
      }
    }

    .images__container {
      display: none;
    }

    .images__container--size-s {
      display: inline-block;
      margin: 10px 0;

      .image__container {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        img {
          width: 90%;
        }
      }
    }

    .footer {
      // margin: 0 auto;
      // width: 100%;
      height: 70px;
      position: static;
      left: 0;
      right: 0;
      bottom: 0;
      p {
        margin: 0 1vw;
      }
    }
  }

  .impressum {
    width: 80%;
    font-size: 14px;
    .logo__container {
      width: 200px;
      margin: 10px 0;
    }
    h1 {
      font-size: 2.1em;
      margin: 30px 0 20px 0;
    }
    .block {
      h2 {
        line-height: 1.7em;
        margin-bottom: 5px;
      }
      h3 {
        line-height: 1.7em;
      }
      p {
        font-size: 1em;
      }
    }
    .notice {
      margin: 40px 0;
      h2 {
        font-size: 1.2em;
      }
    }
  }
}

@media all and (max-width: 319px) {
  .Home {
    padding: unset;
    font-size: 12px;
    min-height: 100%;
    .header {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      height: 100px;

      h1 {
        p {
          font-size: 1.4em;
          margin: 0 8px;
        }
      }
      .logo {
        width: 8vw;
        height: 8vw;
      }

      .langs {
        position: static;
        right: 0;
        margin-bottom: 10px;
      }
    }

    .inner {
      flex: 1;
    }

    .content {
      position: static;
      right: 0;
      left: 0;
      top: 0;

      p {
        font-size: 1.4em;
      }

      .btn__container {
        margin: 1vw 0;

        a {
          width: 25vw;
          margin: 0 1vw;

          img {
            width: 100%;
          }
        }
      }
    }

    .images__container {
      display: none;
    }

    .images__container--size-s {
      display: inline;
      margin: 10px 0;
      .image__container {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;

        img {
          width: 90%;
        }
      }
    }

    .footer {
      height: 100px;

      position: static;
    }
  }
  .impressum {
    width: 80%;
    font-size: 12px;
    .logo__container {
      width: 150px;
      margin: 10px 0;
    }
    h1 {
      font-size: 2em;
      margin: 20px 0 10px 0;
    }
    .block {
      h2 {
        line-height: 1.7em;
        margin-bottom: 5px;
      }
      h3 {
        line-height: 1.7em;
      }
      p {
        font-size: 1em;
        margin-bottom: 5px;
        text-align: justify;
      }
    }
    .notice {
      margin: 40px 0;
      h2 {
        font-size: 1.2em;
      }
    }
  }
}
